<template lang="pug">
.switch-input
  p.switch-input__label {{ label }}
  .switch-input__switch
    p.switch-input__options(v-if="options") {{ options[0] }}
    base-switch(@switch-change="$emit('switchChange', $event)" v-model:checked="data" :no-update="noUpdate")
    p.switch-input__options(v-if="options") {{ options[1] }}
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import BaseSwitch from "@/components/ui/base/BaseSwitch.vue";

export default defineComponent({
  name: "UiSwitch",
  components: {
    BaseSwitch,
  },
  emits: [
    'update:value',
    'switchChange',
  ],
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
    },
    noUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const { value } = toRefs(props);
    const data = ref(props.value);

    watch(data, (newValue) => {
      emit("update:value", newValue);
    });

    watch(value, (newValue) => {
      data.value = newValue;
    });

    return {
      data,
    };
  },
});
</script>

<style lang="scss" scoped>
.switch-input {
  align-items: center;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.switch-input__label,
.switch-input__options {
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  padding: 8px 0;
}

.switch-input__switch {
  display: flex;
  gap: 40px;
  align-items: center;
}
</style>
