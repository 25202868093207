<template lang="pug">
settings-panel
  template(#header) Главное меню
  .main-menu__content
    template(v-for="tab in tabs.filter(t => t.available && switchableTabs.hasOwnProperty(t.key))" :key="tab.key")
      ui-switch(
        no-update
        v-model:value="switchableTabs[tab.key]"
        :label="`Раздел \"${tab.label}\"`"
        @switch-change="onSwitch(tab, $event)"
      )
      template(
        v-if="!!tab.child"
        v-for="child in tab.child"
        :key="child.key"
      )
        ui-switch(
          no-update
          v-model:value="switchableTabs[child.key]"
          :label="`Вкладка \"${child.label}\"`"
          @switch-change="onSwitch(child, $event, tab.key)"
        )
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import UiSwitch from "@/components/ui/switch/UiSwitch.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import type { MenuItemI } from "@/stores/settings/SettingsInterfaces";

export default defineComponent({
  name: "MenuSettings",
  components: {
    UiSwitch,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { tabs, switchableTabs } = toRefs(interfaceSettingsStore);
    const { switchTab, saveInterfaceSettings } = interfaceSettingsStore;

    function onSwitch(tab: MenuItemI, value: boolean, parentKey?: string) {
      switchTab(tab, value, parentKey)
      saveInterfaceSettings()
    }

    return {
      tabs,
      switchableTabs,
      onSwitch,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.settings-panel) {
  max-width: 400px;
}
.main-menu__content {
  display: flex;
  flex-flow: column;
}
</style>
