<template lang="pug">
settings-panel
  template(#header) Удаление лотов при обновлении шаблона
  ui-checkbox(
    large
    id="need-clear-settings"
    label="Удалять старые результаты шаблона при обновлении сохраненных фильтров"
    :model-value="functions.needClearBeforeSave"
    :message="messages.needClear"
    :disabled="roleReadOnly"
    @update:modelValue="updateFunctionsValue('needClearBeforeSave', $event)"
  )
  ui-checkbox(
    large
    id="hide-on-next-call-settings"
    label="Запомнить выбор и не спрашивать при каждом обновлении шаблона"
    :model-value="functions.rememberClearSetting"
    :message="messages.remember"
    :disabled="roleReadOnly"
    @update:modelValue="updateFunctionsValue('rememberClearSetting', $event)"
  )
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "TemplateSettings",
  components: {
    UiCheckbox,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { functions } = toRefs(interfaceSettingsStore);
    const { updateFunctionsValue } = interfaceSettingsStore;

    const { roleReadOnly } = useUserAccess();

    const messages = {
      needClear: 'При удалении результатов, в шаблон перезапишутся все найденные лоты, удовлетворяющие фильтрам (на момент обновления). При сохранении результатов, уже найденные лоты остаются привязанными к редактируемому шаблону.',
      remember: 'По умолчанию, при каждом обновлении шаблона появляется модальное окно с просьбой указать, удалять ли старые результаты поиска. При выборе "запомнить и больше не спрашивать", при обновлении шаблона автоматически проверяется указанный выше параметр.',
    }

    return {
      messages,
      functions,
      updateFunctionsValue,
      roleReadOnly,
    }
  }
})
</script>

<style scoped lang="scss">
</style>
