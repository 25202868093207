<template lang="pug">
label.switch
  input(v-model="model" type="checkbox" @click.stop="onClick(!model)")
  span.switch__slider.switch__round
</template>

<script lang="ts">
import { defineComponent, toRef, ref, watch } from "vue";

export default defineComponent({
  name: "BaseSwitch",
  emits: [
    'switchChange',
    'update:checked',
  ],
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    noUpdate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.checked);

    watch(toRef(props, "checked"), (newVal) => {
      model.value = newVal;
    });

    function onClick(newVal: boolean) {
      emit("switchChange", newVal);
      if (!props.noUpdate) emit("update:checked", newVal);
    }

    return {
      model,
      onClick,
    };
  },
});
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
}

.switch__slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .switch__slider {
  background-color: var(--main-color-blue);
}

input:focus + .switch__slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .switch__slider:before {
  background-color: var(--main-color-white);
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  left: 0;
  transform: translateX(18px);
}

/* Rounded sliders */
.switch__slider.switch__round {
  border-radius: 34px;
}

.switch__slider.switch__round:before {
  border-radius: 50%;
}
</style>
