<template lang="pug">
  settings-panel
    template(#header) Работа с контрактами
    ui-checkbox(
      large
      id="show-dates-dialog"
      label="Показывать диалог перевода контракта по датам при смене статуса"
      :model-value="functions.showDatesOnStatusChange"
      :disabled="roleReadOnly"
      @update:modelValue="updateFunctionsValue('showDatesOnStatusChange', $event)"
    )
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "ContractFunctions",
  components: {
    UiCheckbox,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { functions } = toRefs(interfaceSettingsStore);
    const { updateFunctionsValue } = interfaceSettingsStore;

    const { roleReadOnly } = useUserAccess();

    return {
      roleReadOnly,
      functions,
      updateFunctionsValue,
    }
  }
})
</script>

<style scoped lang="scss">
</style>
