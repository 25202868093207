<template lang="pug">
settings-panel
  template(#header) Отметка лотов просмотренными
  radio-group(:modelValue="mode" :options="options" @update:model-value="updateUnseenMode")

</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import RadioGroup from "@/components/ui/radio/RadioGroup.vue";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

export default defineComponent({
  name: "UnseenSettings",
  components: {
    RadioGroup,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { unseenMode: mode } = toRefs(interfaceSettingsStore);
    const { updateUnseenMode } = interfaceSettingsStore;

    const options = [
      { id: 1, title: 'При нахождении в зоне видимости более 2 секунд',
        message: 'Лот отмечается просмотренным, если его название находится в пределах экрана (в зоне видимости) более 2 секунд' },
      { id: 2, title: 'При взаимодействии с карточкой лота',
        message: 'Лот отмечается просмотренным при клике на карточку (это может быть клик на название/кнопку/пустое пространство в центре и т.д.)' },
      { id: 3, title: 'При клике на кнопку',
        message: 'Лот отмечается просмотренным при клике на кнопку "Отметить лот просмотренным". Кнопка появляется в карточке тендера только в случае, если выбран данный пункт' },
    ]

    return {
      mode,
      options,
      updateUnseenMode,
    }
  }
})
</script>

<style scoped lang="scss">
</style>
