<template lang="pug">
settings-page(title="Настройки функциональности")
  .template-settings
    .interface-settings
      .template-settings
        menu-settings
        auto-functions.full-height(v-if="enableTemplates")
      .template-settings(v-if="enableTemplates")
        unseen-settings
        template-settings(v-if="!enableOnlyExpertTemplates")
    auto-save-functions
    search-settings-functions(v-if="enableLotManagement")
    contract-functions(v-if="enableLotManagement")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import MenuSettings from "@/components/pages/settings/interface/MenuSettings.vue";
import UnseenSettings from "@/components/pages/settings/interface/UnseenSettings.vue";
import TemplateSettings from "@/components/pages/settings/interface/TemplateSettings.vue";
import AutoFunctions from "@/components/pages/settings/interface/AutoFunctions.vue";
import AutoSaveFunctions from "@/components/pages/settings/interface/AutoSaveFunctions.vue";
import SearchSettingsFunctions from "~/components/pages/settings/interface/SearchSettingsFunctions.vue";
import ContractFunctions from "@/components/pages/settings/interface/ContractFunctions.vue";

export default defineComponent({
  name: "InterfaceSettings",
  components: {
    ContractFunctions,
    AutoSaveFunctions,
    SearchSettingsFunctions,
    AutoFunctions,
    TemplateSettings,
    UnseenSettings,
    MenuSettings,
    SettingsPage,
  },
  setup() {

    const {
      enableTemplates,
      enableOnlyExpertTemplates,
      enableLotManagement,
    } = useUserAccess()

    return {
      enableTemplates,
      enableLotManagement,
      enableOnlyExpertTemplates,
    }
  }
});
</script>

<style scoped lang="scss">
.interface-settings {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 40px;
}
.template-settings {
  display: flex;
  flex-flow: column;
  gap: 24px;
}
.full-height {
  height: 100%;
}
</style>
