<template lang="pug">
settings-panel
  template(#header) Работа с поиском
  ui-checkbox(
    large
    disabled
    id="auto-fetch"
    label="Автоматически загружать данные по всем шаблонам при переходе на вкладку 'Поиск'"
    :model-value="functions.autoLoadTemplates"
    @update:modelValue="updateFunctionsValue('autoLoadTemplates', $event)"
  )
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";

export default defineComponent({
  name: "AutoFunctions",
  components: {
    UiCheckbox,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { functions } = toRefs(interfaceSettingsStore);
    const { updateFunctionsValue } = interfaceSettingsStore;

    return {
      functions,
      updateFunctionsValue,
    }
  }
})
</script>

<style scoped lang="scss">
</style>
