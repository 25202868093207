<template lang="pug">
settings-panel
  template(#header) Сохранение состояний страниц
  ui-checkbox.limit(
    v-if="enableSearchTab || enableLotManagement"
    large
    id="save-on-reload"
    label="Восстанавливать состояние страницы при перезагрузке (Поиск, Заявки, Контракты)"
    :message="message.reload"
    :model-value="functions.saveFiltersOnReload"
    @update:modelValue="updateFunctionsValue('saveFiltersOnReload', $event)"
  )
  ui-checkbox.limit(
    v-if="enableSearchTab || enableLotManagement"
    large
    id="save-on-redirect"
    label="Сохранять выбор шаблонов при переходе по вкладкам (Поиск, Заявки, Контракты)"
    :message="message.redirect"
    :model-value="functions.saveFiltersOnRedirect"
    @update:modelValue="updateFunctionsValue('saveFiltersOnRedirect', $event)"
  )
  ui-checkbox.limit(
    v-if="enableAnalyticsTab"
    large
    id="analytics-on-reload"
    label="Восстанавливать состояние страницы при перезагрузке (Аналитика)"
    :message="message.analytics"
    :model-value="functions.saveAnalyticsOnReload"
    @update:modelValue="updateFunctionsValue('saveAnalyticsOnReload', $event)"
  )
  ui-checkbox.limit(
    v-if="enableAnalyticsTab"
    large
    id="analytics-on-refetch"
    label="Сохранять сортировки при изменении условий поиска (Аналитика)"
    :message="message.analytics2"
    :model-value="functions.saveAnalyticsSortingOnFiltersChange"
    @update:modelValue="updateFunctionsValue('saveAnalyticsSortingOnFiltersChange', $event)"
  )
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";

export default defineComponent({
  name: "AutoSaveFunctions",
  components: {
    UiCheckbox,
    SettingsPanel,
  },
  setup() {

    const { enableSearchTab, enableAnalyticsTab, enableLotManagement } = useUserAccess();

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { functions } = toRefs(interfaceSettingsStore);
    const { updateFunctionsValue } = interfaceSettingsStore;

    const message = {
      reload: 'Восстановление происходит на страницах Поиск, Заявки и Контракты (со всеми подвкладками)',
      redirect: 'При выборе данного параметра, селектор шаблонов на вкладках Поиск, Заявки и Контракты будет содержать синхронизированное значение',
      analytics: 'Восстановление происходит на всех вкладках Аналитики',
      analytics2: 'По умолчанию, выбранные сортировки сбрасываются при изменении условий поиска (изменение выбранной компании/фильтров в аналитике по поставщикам/заказчикам, а также при изменении выбора шаблона/фильтров в аналитике по отраслям. При выборе данного параметра, сортировки не будут сбрасыватся (для принудильного сброса сортировок нужно будет воспользоваться кнопкой "очистить сортировки")',
    }

    return {
      message,
      functions,
      enableSearchTab,
      enableLotManagement,
      enableAnalyticsTab,
      updateFunctionsValue,
    }
  }
})
</script>

<style scoped lang="scss">
.limit {
  width: 80%;
}
</style>
