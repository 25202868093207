<template lang="pug">
settings-panel
  template(#header) Работа с лотами в поиске
  ui-checkbox.limit(
    large
    id="search-functions-search"
    label="Фильтровать закупки в быстром поиске, для которых уже созданы заявки"
    message="При быстром поиске из выдачи автоматически скроются закупки, у которых уже установлен статус"
    :model-value="searchSettings.hideActiveLotsInQuickSearch"
    @update:modelValue="updateSearchSettingsValue('hideActiveLotsInQuickSearch', $event)"
  )
  ui-checkbox.limit(
    large
    id="search-functions-templates"
    label="Фильтровать закупки в поиске по шаблонам, для которых уже созданы заявки"
    message="При поиске по шаблонам из выдачи автоматически скроются закупки, у которых уже установлен статус"
    :model-value="searchSettings.hideActiveLotsInSearchByTemplates"
    @update:modelValue="updateSearchSettingsValue('hideActiveLotsInSearchByTemplates', $event)"
  )
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";

export default defineComponent({
  name: "AutoSaveFunctions",
  components: {
    UiCheckbox,
    SettingsPanel,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { searchSettings } = toRefs(interfaceSettingsStore);
    const { updateSearchSettingsValue } = interfaceSettingsStore;

    return {
      searchSettings,
      updateSearchSettingsValue,
    }
  }
})
</script>

<style scoped lang="scss">
.limit {
  width: 80%;
}
</style>
